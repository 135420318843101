'use strict';
$(function () {


    // if (navigator.userAgent.match(/Android/i)
    //     || navigator.userAgent.match(/webOS/i)
    //     || navigator.userAgent.match(/iPhone/i)
    //     || navigator.userAgent.match(/iPad/i)
    //     || navigator.userAgent.match(/iPod/i)
    //     || navigator.userAgent.match(/BlackBerry/i)
    //     || navigator.userAgent.match(/Windows Phone/i)) {
    //     swal({
    //         text: "This tool is best viewed using desktop.",
    //         buttons: {
    //             cancel: "Continue with mobile"
    //         },
    //     });
    // }
    //Custom select
    $('select:not(.search-to):not(.exclude-nice-select):not(.dayselect):not(.categories)').niceSelect();


    //new SimpleBar(document.getElementsByClassName('list'), { autoHide: false });



    // let $mainMenu = $('#main-header .hamburger-menu'),
    //     $notificationMenu = $('#notification-dropdown-trigger'),
    //     $userNav = $('#user-nav-trigger');



    // $(document).mouseup(e => {
    //     if (!$mainMenu.is(e.target) && $mainMenu.has(e.target).length === 0) {
    //         $($mainMenu).find('.hamburger').removeClass('is-active');
    //         $('#main-navigation').removeClass('opened');
    //     }

    //     if (!$notificationMenu.is(e.target) && $notificationMenu.has(e.target).length === 0) {
    //         $($notificationMenu).removeClass('active');
    //         $($notificationMenu).next('.notification-message-dropdown').removeClass('show');
    //     }

    //     if (!$userNav.is(e.target) && $userNav.has(e.target).length === 0) {
    //         $($userNav).removeClass('active');
    //         $('#user-nav-dropdown').removeClass('open');
    //     }
    // });
    // $($mainMenu).on('click', function (e) {

    //     //Close other dropdowns
    //     if ($('#user-nav-trigger').hasClass('active')) {
    //         $('#user-nav-trigger').removeClass('active');
    //     }
    //     if ($('#user-nav-dropdown').hasClass('open')) {
    //         $('#user-nav-dropdown').removeClass('open');
    //     }

    //     if ($('#notification-dropdown-trigger').hasClass('active')) {
    //         $('#notification-dropdown-trigger').removeClass('active');
    //     }
    //     if ($('#notification-dropdown-trigger').next('.notification-message-dropdown').hasClass('show')) {
    //         $('#notification-dropdown-trigger').next('.notification-message-dropdown').removeClass('show');
    //     }



    //     $(this).find('.hamburger').toggleClass('is-active');
    //     $('#main-navigation').toggleClass('opened');
    // });

    // //header notification trigger

    // $($notificationMenu).on('click', function (e) {
    //     e.preventDefault();

    //     //Close other dropdowns
    //     if ($('#user-nav-trigger').hasClass('active')) {
    //         $('#user-nav-trigger').removeClass('active');
    //     }
    //     if ($('#user-nav-dropdown').hasClass('open')) {
    //         $('#user-nav-dropdown').removeClass('open');
    //     }

    //     if ($('#main-navigation').hasClass('opened')) {
    //         $('#main-navigation').removeClass('opened');
    //     }

    //     if ($('#main-header .hamburger-menu').find('.hamburger').hasClass('is-active')) {
    //         $('#main-header .hamburger-menu').find('.hamburger').removeClass('is-active');
    //     }


    //     $(this).toggleClass('active');
    //     $(this).next('.notification-message-dropdown').toggleClass('show');
    // });

    // //User Dropdown 
    // $($userNav).on('click', function (e) {
    //     e.preventDefault();

    //     //Close other dropdowns
    //     if ($('#notification-dropdown-trigger').hasClass('active')) {
    //         $('#notification-dropdown-trigger').removeClass('active');
    //     }
    //     if ($('#notification-dropdown-trigger').next('.notification-message-dropdown').hasClass('show')) {
    //         $('#notification-dropdown-trigger').next('.notification-message-dropdown').removeClass('show');
    //     }

    //     if ($('#main-navigation').hasClass('opened')) {
    //         $('#main-navigation').removeClass('opened');
    //     }

    //     if ($('#main-header .hamburger-menu').find('.hamburger').hasClass('is-active')) {
    //         $('#main-header .hamburger-menu').find('.hamburger').removeClass('is-active');
    //     }


    //     $(this).toggleClass('active');
    //     $('#user-nav-dropdown').toggleClass('open');
    // })


    // $('.months').prop("disabled", true);
    // $('#financial-goal').change(function () {
    //     if ($('#financial-goal').val() == 'monthly') {
    //         $('.months').prop("disabled", false);
    //     } else {
    //         $('.months').prop("disabled", true);
    //     }
    // });
    // $('.date-input').dateDropper({
    //     'data-large-default': true
    // });

    $(document).on('change', '.show-password', function (e) {
        $(this).earch(function () {
            if ($(this).is(":checked")) {
                $(this).find('[type="password"]').attr('type', 'text');
            } else {
                $(this).find('[type="password"]').attr('type', 'password');
            }
        })
    });

    $(document).on('click', '.switch input', function (e) {
        $(this).each(function () {
            if ($(this).is(":checked")) {
                $(this).parent('.switch').next('label').addClass('active');
            } else {
                $(this).parent('.switch').next('label').removeClass('active');
            }
        })
    })

    //Simple bar



    //ToolTip
    $('.link-tooltip').tooltip({
        delay: 200,
        html: true
    })

    $('.sp-tooltip').tooltip({
        delay: 200,
        html: true,
        template: '<div class="tooltip signup-tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
    });



    // $('.data-table__table').basictable({
    //     breakpoint: 1025,
    // });

    // $(window).on("load resize", function (event) {
    //     if ($(this).width() < 1024) {
    //         var tableNav = $('thead').find('.th-navigation').html();

    //         $('.data-table__mobileMenu').html(tableNav);


    //         $(document).on('click', '.table-dropdown-trigger', function (e) {
    //             $(this).each(function (element) {
    //                 $(document).find('.table-dropdown').toggleClass('opened');
    //             });
    //         })
    //     } else {
    //         /* $('#rightbar, #leftbar').addClass('open');
    //         $('#sp-rightbar-toggle, #sp-leftbar-trigger').addClass('active'); */
    //     }
    // });


    var inputQuantity = [];
    $(".quantity").each(function (i) {
        inputQuantity[i] = this.defaultValue;
        $(this).data("idx", i); // save this field's index to access later
    });
    $(".quantity").on("keyup", function (e) {
        var $field = $(this),
            val = this.value,
            $thisIndex = parseInt($field.data("idx"), 3); // retrieve the index
        //        window.console && console.log($field.is(":invalid"));
        //  $field.is(":invalid") is for Safari, it must be the last to not error in IE8
        if (this.validity && this.validity.badInput || isNaN(val) || $field.is(":invalid")) {
            this.value = inputQuantity[$thisIndex];
            return;
        }
        if (val.length > Number($field.attr("maxlength"))) {
            val = val.slice(0, 3);
            $field.val(val);
        }
        inputQuantity[$thisIndex] = val;
    });


    $("#order-info-tab").hide();
    $(".sOrder-details__tab").on("click", function (e) {
        $(".order-content-tab").hide();
        $(".sOrder-details__tab").removeClass("sOrder-details__tab--active");
        $($(this).attr("data-target")).show();
        $(this).addClass("sOrder-details__tab--active");
    });

    $('.restaurant_details-accordion-header').on('click', function () {
        var $accordionItem = $(this).closest('.restaurant_details-accordion-item');
        $accordionItem.toggleClass('active');
    });

    $('.tab_item').on('click', function () {
        var target = $(this).data('target');
        $('.tab_item').removeClass('active');
        $(this).addClass('active');
        $('.category_block').hide();
        if (target === 'all') {
            $('.category_block').css('display', '');
        } else {
            $('.category_block[data-category="' + target + '"]').css('display', '');
        }
    });

    // categories blocks
    // var categories = ['starters', 'salads', 'beverages', 'main_dishes', 'soups', 'deserts'];
    // categories.forEach(function (category) {
        $(".category_header").on('click', function () {
            $(this).closest('.category_block').toggleClass('collapsed');
        });
    // });
});